import { WithResourceType } from '@/permissions'

type AppBarProps = {
  label: string
  basePath: string
  path?: string
  icon: string
}

export const useSideBar = () => {
  const adminSidebarItem: WithResourceType<AppBarProps>[] = [
    {
      label: '見積依頼',
      basePath: '/admin/quotesrequestlist',
      path: '/admin/quotesrequestlist/ocean',
      icon: 'insert_drive_file',
      resourceType: 'QUOTE_REQUEST',
    },
    {
      label: '料金管理表',
      basePath: '/admin/price-managements',
      path: '/admin/price-managements/fcl',
      icon: 'format_list_bulleted',
      resourceType: 'PRICE_MANAGEMENT',
    },
    {
      label: '買値',
      basePath: '/admin/purchase',
      path: '/admin/purchase/fcl',
      icon: 'request_quote',
      resourceType: 'PURCHASE',
    },
    {
      label: '売値',
      basePath: '/admin/selling',
      path: '/admin/selling/fcl',
      icon: 'request_quote',
      resourceType: 'SELLING',
    },
    {
      label: '見積',
      basePath: '/admin/quotes',
      path: '/admin/quotes/ocean',
      icon: 'transform',
      resourceType: 'QUOTES',
    },
    {
      label: 'スケジュール',
      basePath: '/admin/schedule',
      icon: 'event_available',
      resourceType: 'SCHEDULE',
    },
    {
      label: 'ブッキング',
      basePath: '/admin/booking',
      icon: 'check_box',
      resourceType: 'BOOKING',
    },
    {
      label: 'シップメント',
      basePath: '/admin/shipment',
      icon: 'place',
      resourceType: 'SHIPMENT',
    },
    {
      label: '請求書',
      basePath: '/admin/invoicelist',
      icon: 'local_atm',
      resourceType: 'BILLING',
    },
    {
      label: '会社管理',
      basePath: '/admin/company-management',
      path: '/admin/company-management/forwarder/ocean',
      icon: 'manage_accounts',
      resourceType: 'NETWORK',
    },
    {
      label: 'ユーザー会社',
      basePath: '/admin/user-companies',
      icon: 'apartment',
      resourceType: 'USER_COMPANY',
    },
    {
      label: '共通データ',
      basePath: '/admin/master-data',
      path: '/admin/master-data/cargoes',
      icon: 'add_circle',
      resourceType: 'COMMODITY',
    },
    {
      label: 'マイルストーン',
      basePath: '/admin/milestone',
      icon: 'add_task',
      resourceType: 'MILESTONE',
    },
    {
      label: 'フライト\nスケジュール',
      basePath: '/admin/air-transport-schedules',
      icon: 'flight_takeoff',
      resourceType: 'AIR_TRANSPORT_SCHEDULE',
    },
  ]

  return {
    adminSidebarItem,
  }
}
