import {
  DeliveryTimeSpanEnum,
  PayerTypeEnum,
  TransportMethodEnum,
} from '@api/models'

/** Admin権限 */
export const AUTHORITY_ADMIN = 'admin'

/** User権限 */
export const AUTHORITY_USER = 'user'

/** ログイン直後に遷移する画面URL */
export const USER_ROOT_URL = '/user/dashboard'
export const ADMIN_ROOT_URL = '/admin'
export const PORTRICH_ROOT_URL = '/admin/quotesrequestlist/ocean'
export const FORWARDER_ROOT_URL = '/admin/booking'

export const NOT_FOUND_URL = '/404'

/** boolean値 */
export const BOOL = {
  TRUE: 1,
  FALSE: 0,
}

/** 通貨 */
export const CURRENCY_UNITS = {
  USD: '$',
  JPY: '¥',
  VND: '₫',
  EUR: '€',
  CNY: 'Y',
  HKD: 'HK$',
  INR: 'Rs',
  SGD: 'S$',
  AUD: 'A$',
  SEK: 'SEK',
  NOK: 'NKr',
  TRY: '₺',
  ZAR: 'R',
  MXN: 'Mex$',
  GBP: '£',
  KRW: 'W',
  TWD: 'NT$',
  IDR: 'Rp',
  THB: 'Bht',
  NZD: 'NZ$',
  DKK: 'Dkr',
  CHF: 'SwF',
  RUB: '₽',
  CAD: 'Can$',
  BRL: 'R$',
}

export const FREIGHT_METHOD = {
  OCEAN: 0,
  TRUCK: 1,
  AIR: 2,
}

/** 輸送形態 : FCLの場合、0 / LCLの場合、1 */
export const SHIPPING_METHOD = {
  FCL: 0,
  LCL: 1,
}

export const SHIPPING_METHOD_TYPE = {
  [SHIPPING_METHOD.FCL]: 'FCL',
  [SHIPPING_METHOD.LCL]: 'LCL',
} as const

/** 輸入 or 輸出 : 輸出の場合、0 / 輸入の場合、1 */
export const TRANSPORT = {
  EXPORT: 0,
  IMPORT: 1,
}

export const TRANSPORT_TYPE = {
  [TRANSPORT.EXPORT]: '輸出',
  [TRANSPORT.IMPORT]: '輸入',
} as const

/** Bookingステータス */
export const BOOKING_STATUS = {
  NO_STATUS: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
  CANCELED: 4,
}

export const BOOKING_STATUS_TYPES = {
  [BOOKING_STATUS.NO_STATUS]: 'ステータスなし',
  [BOOKING_STATUS.IN_PROGRESS]: '進行中',
  [BOOKING_STATUS.COMPLETED]: '完了',
  [BOOKING_STATUS.FAILED]: '無効',
  [BOOKING_STATUS.CANCELED]: 'キャンセル',
} as const

/** 台貫依頼の有無 */
export const NEED_TRUCK_SCALE = {
  UNNECESSARY: 0,
  NEED: 1,
}

export const NEED_TRUCK_SCALE_TYPES = {
  [NEED_TRUCK_SCALE.UNNECESSARY]: 'なし',
  [NEED_TRUCK_SCALE.NEED]: 'あり',
} as const

/** 依頼状況："1"の場合、依頼あり / "2"の場合、依頼なし */
export const REQUEST_STATUS = {
  REQUESTED: '1',
  NOT_REQUESTED: '2',
}

/** ドキュメント */
export const FILE_TYPE = {
  DOCUMENT: 'ドキュメント',
  PRIVATE_DOCUMENT: 'プライベートドキュメント',
  USER_DOCUMENT: 'user_document',
}

/** 文言 */
export const LABEL = {
  ORIGIN_DELIVERY: 'Origin Delivery',
  ORIGIN_CUSTOMS: 'Origin Customs',
  ORIGIN_CUSTOMS_FEE: 'Origin Customs Fee',
  ORIGIN_CUSTOMS_HANDLING_FEE: 'Origin Customs Handling Fee',
  DESTINATION_DELIVERY: 'Destination Delivery',
  DESTINATION_CUSTOMS: 'Destination Customs',
  DESTINATION_CUSTOMS_FEE: 'Destination Customs Fee',
  DESTINATION_CUSTOMS_HANDLING_FEE: 'Destination Customs Handling Fee',
  DANGEROUS_GOODS: 'Dangerous Goods',
}

export const INCOTERMS = [
  'EXW',
  'FCA',
  'FAS',
  'FOB',
  'CFR',
  'CIF',
  'CPT',
  'CIP',
  'DPU',
  'DAP',
  'DDP',
]

export const DELIVERY = {
  WORK_PLACE: '配送先名',
  DESIRED_DATE_TIME: '配送希望日時',
  PICK_UP_DATE_TIME: '回収希望日時',
  CUSTOMS_CLEARANCE: '通関許可日',
  DELIVERY_DATES: '納品希望日',
  CONTAINER_SIZE: 'コンテナサイズ',
  TRUCK_SIZE: 'トラックサイズ',
}

export const TASK_PATTERN = {
  CONFIRMATION: 0,
  UPLOAD: 1,
}

export const TASK_PATTERN_LABEL = {
  [TASK_PATTERN.CONFIRMATION]: '応答',
  [TASK_PATTERN.UPLOAD]: 'アップロード',
} as const

export const CHASSIS = {
  TWIN_AXLE: 0,
  TRI_AXLE: 1,
}

export const CHASSIS_TYPES = {
  [CHASSIS.TWIN_AXLE]: '2軸',
  [CHASSIS.TRI_AXLE]: '3軸',
} as const

export const TASK = {
  CONFIRMATION: '確認済にする',
  UPLOAD: 'アップロード',
}

export const ORDER_BY_EXPIRATION_MONTH = {
  ASK: 'expiration_month',
  DESK: '-expiration_month',
}

export const ORDER_BY_CREATED_AT = {
  ASK: 'created_at',
  DESK: '-created_at',
}

export const ORDER_BY_UPDATED_AT = {
  ASK: 'updated_at',
  DESK: '-updated_at',
}

export const ORDER_BY_CASE_NUMBER = {
  ASK: 'portrich_case_number',
  DESK: '-portrich_case_number',
}

export const ORDER_BY_CASE_NUMBER_BILLING = {
  ASK: 'shipment__portrich_case_number',
  DESK: '-shipment__portrich_case_number',
}

export const ORDER_BY_PAYMENT_DEADLINE = {
  ASK: 'payment_deadline',
  DESK: '-payment_deadline',
}

export const ORDER_BY_CUT_DAY = {
  ASK: 'cut_day',
  DESK: '-cut_day',
}

export const ORDER_BY_ETD = {
  ASK: 'etd',
  DESK: '-etd',
}

export const ORDER_BY_ETA = {
  ASK: 'eta',
  DESK: '-eta',
}

export const QUOTES_TAB_VALUES = { OCEAN: 0, AIR: 1, TRUCK: 2 }

/** 支払い状況 */
export const PAYMENT_TYPES = {
  paid: 'Paid',
  unpaid: 'Unpaid',
}

export const LOCATION = {
  ORIGIN: 0,
  DESTINATION: 1,
}

export const LOCATION_TYPES = {
  [LOCATION.ORIGIN]: 'Origin',
  [LOCATION.DESTINATION]: 'Destination',
} as const

export const CUSTOM_TYPES = {
  [LOCATION.ORIGIN]: '輸出通関',
  [LOCATION.DESTINATION]: '輸入通関',
} as const

export const CHARGE_KEYS = ['freight', 'origin', 'destination', 'additional']

export const SCHEDULE_TYPE = {
  ETD: 0,
  ETA: 1,
  DESIRED_DELIVERY_DATETIME: 2,
}

export const COMPONENT_TYPE = {
  FCL: 0,
  LCL: 1,
  DRAYAGE: 2,
  TRUCK: 3,
  CUSTOMS: 4,
  AIR: 5,
}

export const QUOTE_REQUEST_COMPONENT_TYPE = {
  OCEAN: 0,
  TRUCK: 1,
  AIR: 2,
}

export const QUOTE_COMPONENT_TYPE = {
  OCEAN: 0,
  OTHER: 1,
  AIR: 2,
}

export const quoteListBtnTitle = ['Ocean', 'Other', 'Air']

export const quoteListRouteToIndexMap: Record<string, number> = {
  '/admin/quotes/ocean': QUOTE_COMPONENT_TYPE.OCEAN,
  '/admin/quotes/other': QUOTE_COMPONENT_TYPE.OTHER,
  '/admin/quotes/air': QUOTE_COMPONENT_TYPE.AIR,
}

export const quoteListTabRoutes = {
  [QUOTE_COMPONENT_TYPE.OCEAN]: '/admin/quotes/ocean',
  [QUOTE_COMPONENT_TYPE.OTHER]: '/admin/quotes/other',
  [QUOTE_COMPONENT_TYPE.AIR]: '/admin/quotes/air',
}

export const sellingPriceListBtnTitle = [
  'FCL',
  'LCL',
  'ドレージ',
  'トラック',
  '通関',
  'AIR',
]

export const sellingPriceListRouteToIndexMap: Record<string, number> = {
  '/admin/selling/fcl': COMPONENT_TYPE.FCL,
  '/admin/selling/lcl': COMPONENT_TYPE.LCL,
  '/admin/selling/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/selling/truck': COMPONENT_TYPE.TRUCK,
  '/admin/selling/customs': COMPONENT_TYPE.CUSTOMS,
  '/admin/selling/air': COMPONENT_TYPE.AIR,
}

export const sellingPriceListTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/selling/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/selling/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/selling/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/selling/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/selling/customs',
  [COMPONENT_TYPE.AIR]: '/admin/selling/air',
}

export const purchaseBtnTitle = [
  'FCL',
  'LCL',
  'ドレージ',
  'トラック',
  '通関',
  'AIR',
]

export const purchaseTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/purchase/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/purchase/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/purchase/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/purchase/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/purchase/customs',
  [COMPONENT_TYPE.AIR]: '/admin/purchase/air',
}

export const purchaseRouteToIndexMap: Record<string, number> = {
  '/admin/purchase/fcl': COMPONENT_TYPE.FCL,
  '/admin/purchase/lcl': COMPONENT_TYPE.LCL,
  '/admin/purchase/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/purchase/truck': COMPONENT_TYPE.TRUCK,
  '/admin/purchase/air': COMPONENT_TYPE.AIR,
}

export const quotesRequestListBtnTitle = ['OCEAN', 'TRUCK', 'AIR']

export const quotesRequestListTabRoutes = {
  [QUOTE_REQUEST_COMPONENT_TYPE.OCEAN]: '/admin/quotesrequestlist/ocean',
  [QUOTE_REQUEST_COMPONENT_TYPE.TRUCK]: '/admin/quotesrequestlist/truck',
  [QUOTE_REQUEST_COMPONENT_TYPE.AIR]: '/admin/quotesrequestlist/air',
}

export const quotesRequestListRouteToIndexMap: Record<string, number> = {
  '/admin/quotesrequestlist/ocean': QUOTE_REQUEST_COMPONENT_TYPE.OCEAN,
  '/admin/quotesrequestlist/truck': QUOTE_REQUEST_COMPONENT_TYPE.TRUCK,
  '/admin/quotesrequestlist/air': QUOTE_REQUEST_COMPONENT_TYPE.AIR,
}

export const forwarderBtnTitle = ['OCEAN', 'AIR']

export const forwarderRouteToIndexMap: Record<string, number> = {
  '/admin/company-management/forwarder/ocean': 0,
  '/admin/company-management/forwarder/air': 1,
}

export const forwarderTabRoutes = {
  0: '/admin/company-management/forwarder/ocean',
  1: '/admin/company-management/forwarder/air',
}

export const priceManagementBtnTitle = [
  'FCL',
  'LCL',
  'Drayage',
  'Truck',
  '通関',
]

export const priceManagementRouteToIndexMap: Record<string, number> = {
  '/admin/price-managements/fcl': COMPONENT_TYPE.FCL,
  '/admin/price-managements/lcl': COMPONENT_TYPE.LCL,
  '/admin/price-managements/drayage': COMPONENT_TYPE.DRAYAGE,
  '/admin/price-managements/truck': COMPONENT_TYPE.TRUCK,
  '/admin/price-managements/customs': COMPONENT_TYPE.CUSTOMS,
}

// TODO:Tabsが順番に依存している。定数を使うと通関が4になっているため、現状使えない
export const priceManagementTabRoutes = {
  [COMPONENT_TYPE.FCL]: '/admin/price-managements/fcl',
  [COMPONENT_TYPE.LCL]: '/admin/price-managements/lcl',
  [COMPONENT_TYPE.DRAYAGE]: '/admin/price-managements/drayage',
  [COMPONENT_TYPE.TRUCK]: '/admin/price-managements/truck',
  [COMPONENT_TYPE.CUSTOMS]: '/admin/price-managements/customs',
}

export const SHIPPING_COMPANY_TYPE = {
  MAERSK: 1,
  SEALAND_A_MAERSK: 3,
  ONE: 4,
}

export const APPROACH_TYPE = {
  CUT: 0,
  ETD: 1,
  ETA: 2,
}

export const APPROACH_TYPES = {
  [APPROACH_TYPE.CUT]: 'CY CUT',
  [APPROACH_TYPE.ETD]: 'ETD',
  [APPROACH_TYPE.ETA]: 'ETA',
} as const

export const FILTERED_DATE_TYPE = {
  CUT: 0,
  ETD: 1,
  ETA: 2,
  COLLECT: 3,
  CARRY_OUT: 4,
  TASK_DEADLINE: 5,
}

export const FILTERED_DATE_TYPES = {
  [FILTERED_DATE_TYPE.CUT]: 'CY CUT',
  [FILTERED_DATE_TYPE.ETD]: 'ETD',
  [FILTERED_DATE_TYPE.ETA]: 'ETA',
  [FILTERED_DATE_TYPE.COLLECT]: '集荷日',
  [FILTERED_DATE_TYPE.CARRY_OUT]: '搬出日',
  [FILTERED_DATE_TYPE.TASK_DEADLINE]: 'タスク期限日',
}

export const FILTER_RAGE_TYPES = {
  TOMORROW: 0,
  WEEK: 1,
  TODAY: 2,
}

export const PAYMENT_TYPE = {
  UPLOADED: 1,
}

export const FREIGHT_METHOD_TYPE = {
  [FREIGHT_METHOD.OCEAN]: 'OCEAN',
  [FREIGHT_METHOD.TRUCK]: 'TRUCK',
  [FREIGHT_METHOD.AIR]: 'AIR',
} as const
export const QUOTES_REGISTER_TYPES = {
  SELECT_PURCHASE: '買値選択',
  REGISTER_WITH_PURCHASE: '買値登録',
  // SELECT_SELLING: '売値選択',
}

export const quotesRegisterTabRoutes = {
  [QUOTES_REGISTER_TYPES.SELECT_PURCHASE]: 0,
  [QUOTES_REGISTER_TYPES.REGISTER_WITH_PURCHASE]: 1,
  // [QUOTES_REGISTER_TYPES.SELECT_SELLING]: 2,
}

export const FREIGHT_COLLECTIONS = {
  PRE_PRE: 0,
  PRE_COL: 1,
  COL_COL: 2,
  COL_PRE: 3,
}

export const FREIGHT_COLLECTIONS_TYPE = {
  [FREIGHT_COLLECTIONS.PRE_PRE]: 'PRE_PRE',
  [FREIGHT_COLLECTIONS.PRE_COL]: 'PRE_COL',
  [FREIGHT_COLLECTIONS.COL_COL]: 'COL_COL',
  [FREIGHT_COLLECTIONS.COL_PRE]: 'COL_PRE',
}

export const FREIGHT_COLLECTIONS_PAYER_TYPE = {
  [FREIGHT_COLLECTIONS.PRE_PRE]: {
    master_payment_type: PayerTypeEnum.PREPAID,
    house_payment_type: PayerTypeEnum.PREPAID,
  },
  [FREIGHT_COLLECTIONS.PRE_COL]: {
    master_payment_type: PayerTypeEnum.PREPAID,
    house_payment_type: PayerTypeEnum.COLLECT,
  },
  [FREIGHT_COLLECTIONS.COL_COL]: {
    master_payment_type: PayerTypeEnum.COLLECT,
    house_payment_type: PayerTypeEnum.COLLECT,
  },
  [FREIGHT_COLLECTIONS.COL_PRE]: {
    master_payment_type: PayerTypeEnum.COLLECT,
    house_payment_type: PayerTypeEnum.PREPAID,
  },
}
export const LAND_TRANSPORT_WORK_TIME_SPAN = {
  [DeliveryTimeSpanEnum.AM]: 'AM',
  [DeliveryTimeSpanEnum.PM]: 'PM',
  [DeliveryTimeSpanEnum.TIME_SPECIFIED]: '時間指定あり',
  [DeliveryTimeSpanEnum.WITHIN_SAME_DAY]: '当日中',
}

export const LAND_TRANSPORT_METHODS = {
  [TransportMethodEnum.CHARTER]: 'チャーター',
  [TransportMethodEnum.CHARTER_NOT_SAME_DAY]: 'チャーター(積み置き)',
  [TransportMethodEnum.CHARTER_SAME_DAY]: 'チャーター(当日)',
  [TransportMethodEnum.CONSOLIDATION]: '混載',
  [TransportMethodEnum.DRAYAGE]: 'ドレージ',
}

// Documents_for_tradeがリレーションを持つモデル
export const DOCUMENT_RELATION_TYPE = {
  SHIPMENT: 'shipment',
  MARINE_PRICE_MANAGEMENT: 'marine_trade_price_management',
  CUSTOMS_PRICE_MANAGEMENT: 'customs_clearance_price_management',
}

export const PRICE_TYPE = {
  OCEAN_FREIGHT: 1,
  ORIGIN_CUSTOMS_DUTY_OR_CONSUMPTION_TAX: 25,
  ORIGIN_CUSTOMS_CLEARANCE_FEE: 26,
  ORIGIN_CUSTOMS_HANDLING_FEE: 27,
  ORIGIN_CUSTOMS_INSPECTION_FEE: 28,
  DEST_CUSTOMS_DUTY_OR_CONSUMPTION_TAX: 51,
  DEST_CUSTOMS_CLEARANCE_FEE: 52,
  DEST_CUSTOMS_HANDLING_FEE: 53,
  DEST_CUSTOMS_INSPECTION_FEE: 54,
}

export const COMPANY_TYPE = {
  USER_COMPANY: 'userCompany',
  MARINE_FORWARDER: 'marineForwarder',
  AIR_FORWARDER: 'airForwarder',
  SHIPPING_COMPANY: 'shippingCompany',
  TRUCK_CARRIER: 'truckCarrier',
  CUSTOMS_BROKER: 'customsBroker',
  WAREHOUSING_COMPANY: 'warehousingCompany',
}

export const FREIGHT_SHIPPING_METHOD_TYPE = {
  ...SHIPPING_METHOD_TYPE,
  [FREIGHT_METHOD.AIR]: 'AIR',
}
